import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, first, tap } from 'rxjs';
import { Store } from '@ngxs/store';
import { GoogleSignInSuccess, Logout } from '../store/auth.actions';
import { ShowAlert } from '@shared/store';
import { AlertType } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthGoogleService {
  private http = inject(HttpClient);
  private store = inject(Store);
  apiBaseUrl = environment.apiBaseUrl;

  authenticateGoogleSignIn(token: string) {
    return this.http
      .post(`${this.apiBaseUrl}auth/google`, { token })
      .pipe(
        first(),
        tap((response: any) => {
          const { token, userId } = {
            token: response.accessToken,
            userId: response.userId,
          };
          this.store.dispatch(new GoogleSignInSuccess({ token, userId }));
        }),
        catchError((error) => {
          const errorMessage = error.error.errors[0].description || 'An error occurred Try again later!';
          this.store.dispatch(new Logout());
          this.store.dispatch(new ShowAlert({ message: errorMessage, type: AlertType.Error }));
          throw error;
        }),
      )
      .subscribe();
  }
}
